import {defineStore} from 'pinia';
import {computed, ref} from '#imports';
import {useAxiosInstance} from '@/composables/useAxiosInstance';
import {fetchFromApi, useAuthFetch} from '@/composables/useRequest';
import {
  ADD_FORM_CALLBACK,
  ADD_FORM_CONSULT,
  ADD_FORM_CONTACT,
  ADD_FORM_FAST,
  ADD_FORM_MANAGER,
  ADD_FORM_TESTING,
  ADD_FORM_VACANCY,
  GET_CONTACT_CITIES,
  GET_CONTACT_INFO,
} from '@/composables/useURL';
import type {
  IConsultForm,
  IContactCallForm,
  IContactForm,
  ISendManagerForm,
  ISendTestingForm,
} from '@/types/pages/contact';
import type {IFormFast} from '@/types/pages/form';
import type {IContactCitiesResponse, IContactInfoResponse} from '@/types/store/contact';

const sendFeedBackForm = async (data: IContactForm) => {
  const axios = useAxiosInstance();
  return await axios.post(ADD_FORM_CONTACT, data);
};

const sendModalForm = async (body: IContactCallForm) => {
  const axios = useAxiosInstance();
  return await axios.post(ADD_FORM_CALLBACK, body);
};

const sendManagerForm = async (body: ISendManagerForm) => {
  const axios = useAxiosInstance();
  return await axios.post(ADD_FORM_MANAGER, body);
};

const sendTestingForm = async (body: ISendTestingForm) => {
  const axios = useAxiosInstance();
  return await axios.post(ADD_FORM_TESTING, body);
};

const sendVacancyForm = async (body: FormData) => {
  const axios = useAxiosInstance();
  return await axios.post(ADD_FORM_VACANCY, body);
};

const sendConsultForm = async (body: IConsultForm) => {
  const axios = useAxiosInstance();
  return await axios.post(ADD_FORM_CONSULT, body);
};

const sendFastForm = async (form: IFormFast) => {
  await useAuthFetch(ADD_FORM_FAST, {
    method: 'post',
    body: form,
  });
};

export const useContactStore = defineStore('contact', () => {
  const isContactModal = ref(false);

  const citiesList = ref<IContactCitiesResponse[]>([]);
  const selectedCity = ref<IContactCitiesResponse>();
  const unSelectedCities = computed(() =>
    citiesList.value.filter((city) => city !== selectedCity.value),
  );

  const contactInfo = ref<IContactInfoResponse>();
  const info = computed(() => contactInfo.value?.data[0]);
  const typesMessage = computed(() => contactInfo.value?.typesMessage);
  const coords = computed(() => contactInfo.value?.data[0].city.coord || []);

  const getContactInfoByCity = async () => {
    if (selectedCity.value == null) {
      return;
    }

    const data = await fetchFromApi<IContactInfoResponse>(GET_CONTACT_INFO, {
      query: {city: selectedCity.value.code},
    });
    contactInfo.value = data;
  };

  const getCities = async () => {
    if (citiesList.value.length > 0) {
      return;
    }

    const response = await fetchFromApi<IContactCitiesResponse[]>(GET_CONTACT_CITIES);

    citiesList.value = response;
    selectedCity.value = response.at(0);

    await getContactInfoByCity();
  };

  // const getSessId = async () => {
  //   const data = await fetchFromApi(GET_GENERAL_SESS_ID);
  //   return data.sessid;
  // };

  return {
    isContactModal,

    selectedCity,
    unSelectedCities,

    contactInfo,
    info,
    typesMessage,
    coords,

    getCities,
    getContactInfoByCity,
    sendFeedBackForm,
    sendModalForm,
    sendManagerForm,
    sendTestingForm,
    sendVacancyForm,
    sendConsultForm,
    sendFastForm,
  };
});
